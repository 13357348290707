import { createSelector } from 'reselect'
import { getEntities, getEntity, isFetching, getFetchError, getObjId } from './_utils'
import { getFile, getLanguage, getEvents, getEventCover, getEventFiles } from 'redux/selectors'
import { List, Set } from 'immutable'

export const getUser = getEntity('users')

export const getUserAvatar = createSelector(
  getUser,
  (state) => state,
  (user, state) => getFile(state, getObjId(user, 'avatar')),
)
export const getUserLanguage = createSelector(
  getUser,
  (state) => state,
  (user, state) => getLanguage(state, getObjId(user, 'language')),
)

export const getUserLanguages = createSelector(
  getUser,
  (state) => state,
  (user, state) => user.languages.map((id) => getLanguage(state, id)),
)
export const getUserEvents = createSelector(
  getEvents,
  (_, userId) => parseInt(userId, 10),
  (events, userId) => events.filter((event) => event.user_id === userId).toList(),
)

export const getUserFiles = createSelector(getUser, getEntities('files'), (user, files) =>
  files
    .filter((file) => file.account_id === (user.account_id || user.account))
    .toList()
    .sort((a, b) => a.order - b.order),
)

export const getUserSortedEventFiles = createSelector(
  getUserFiles,
  getUserEvents,
  (state) => state,
  (_userFiles, userEvents, state) => {
    const covers = userEvents.reduce((acc, event) => {
      const cover = getEventCover(state, event.id)
      if (!cover.id) return acc
      return acc.push(cover)
    }, List())

    const files = userEvents
      .reduce((uniqueFiles, event) => uniqueFiles.union(getEventFiles(state, event.id)), new Set()) // May be an empty Set
      .filter((file) => !covers.find((c) => c.id === file.id))
      .sort((a, b) => a.order - b.order)
      .toList()

    return files.concat(covers)
  },
)

export const fetchingUser = (state, id) => isFetching(`/users/${id}`)(state)

export const getFetchUserError = (state, id) => getFetchError(`/users/${id}`)(state)
