import { Map } from 'immutable'
import settings from 'settings'
import axios from 'axios'

export function formatUserPlace(user) {
  return [user.locality, user.country].filter((u) => !!u).join(', ')
}

export function getLocalityAndCountry({ place, stringFormattingFunction = (placeArray) => placeArray.join(', ') }) {
  const locality = getLocality(place)
  const country = getCountry(place)
  return stringFormattingFunction([locality, country].filter((l) => !!l))
}

export function getCountry(place) {
  return Map.isMap(place) ? place.get('country') : place.country
}

export function getLocality(place) {
  if (Map.isMap(place)) place = place.toJS()
  return place.locality || place.administrative_area_level_2 || place.administrative_area_level_1
}

function isValidViewport(viewport) {
  return !!(
    viewport?.northeast?.lat &&
    viewport?.northeast?.lng &&
    viewport?.southwest?.lat &&
    viewport?.southwest?.lng
  )
}

export function getBounds(place, viewport) {
  let v = viewport?.toJS() || viewport
  v = isValidViewport(v) ? v : null

  const p = place?.toJS() || place
  const geometry = p?.coordinates?.geometry
  const bounds = v || geometry?.viewport || geometry?.bounds || geometry?.location || {}

  if (Object.keys(bounds).length === 0) return null
  return bounds
}

export const placeSkeleton = {
  address: null,
  formatted: null,
  street_number: null,
  route: null,
  locality: null,
  administrative_area_level_1: null,
  administrative_area_level_2: null,
  country: null,
  country_iso: null,
  postal_code: null,
  lat: null,
  lng: null,
  provider: null,
  provider_id: null,
}

/**
 * Extract a place component value based on type and format
 * @param  {Object} place  A Google Place object
 * @param  {String} type   A type of information to look for (country, locality...)
 * @param  {String} format A type of format to retrieve (long_name, short_name...)
 * @return {String}        The value for the specified request
 */
export function getPlaceComponent({ address_components: address }, type, format) {
  if (!address) return null
  // address_component have the following shape
  // { short_name: 'xyz', long_name: 'xyz', types: [ 'abc', 'def', 'ijk' ]}
  const component = address
    .filter((component) => component.types.indexOf(type) >= 0)
    .filter((component) => format in component)
    .shift()

  return component ? component[format] : null
}

// TODO: deprecate
export function formatPlace(googlePlace) {
  if (!googlePlace || !googlePlace.place_id) return placeSkeleton
  const { location = { lat: 0, lng: 0 } } = googlePlace.geometry
  return {
    address: googlePlace.formatted_address,
    formatted: googlePlace.formatted_address,
    street_number: getPlaceComponent(googlePlace, 'street_number', 'short_name'),
    route: getPlaceComponent(googlePlace, 'route', 'long_name'),
    locality:
      getPlaceComponent(googlePlace, 'locality', 'long_name') ||
      getPlaceComponent(googlePlace, 'administrative_area_level_3', 'long_name'),
    administrative_area_level_1: getPlaceComponent(googlePlace, 'administrative_area_level_1', 'short_name'),
    administrative_area_level_2: getPlaceComponent(googlePlace, 'administrative_area_level_2', 'short_name'),
    country: getPlaceComponent(googlePlace, 'country', 'long_name'),
    country_iso: getPlaceComponent(googlePlace, 'country', 'short_name'),
    postal_code: getPlaceComponent(googlePlace, 'postal_code', 'short_name'),
    lat: typeof location.lat === 'function' ? location.lat() : location.lat, // compatibility between google api and screen api
    lng: typeof location.lng === 'function' ? location.lng() : location.lng, // compatibility between google api and screen api
    geometry: googlePlace.geometry,
    provider: 'google',
    provider_id: googlePlace.place_id,
  }
}

// TODO: eventually formatPlace and formatPlaceRecord should be the same function
export function formatPlaceRecord(googlePlace) {
  if (!googlePlace || !googlePlace.place_id) return placeSkeleton
  const { location = { lat: 0, lng: 0 } } = googlePlace.geometry
  const place = formatPlace(googlePlace)
  return {
    ...place,
    id: googlePlace.id || googlePlace.place_id,
    provider: 'google',
    provider_id: googlePlace.place_id,
    coordinates: {
      latitude: typeof location.lat === 'function' ? location.lat() : location.lat, // compatibility between google api and screen api
      longitude: typeof location.lng === 'function' ? location.lng() : location.lng, // compatibility between google api and screen api
      geometry: googlePlace.geometry,
    },
  }
}

export async function fetchAutocomplete({ query, type, prox, language }) {
  if (!query) return []
  try {
    const response = await axios(settings.geo.autocomplete, {
      params: new URLSearchParams(
        Object.assign({ q: query }, type && { type }, prox && { prox }, language && { language }),
      ),
    })
    return response.data.results
  } catch (e) {
    return []
  }
}

export async function fetchGeocode({ query, language }) {
  if (!query) return []
  try {
    const response = await axios(settings.geo.geocode, {
      params: new URLSearchParams(Object.assign({ q: query }, language && { language })),
    })
    return response.data.results
  } catch (e) {
    return []
  }
}

export async function fetchReverse({ lat, lng, types }) {
  if (!lat || !lng) return []
  try {
    const response = await axios(settings.geo.reverse, {
      params: new URLSearchParams(Object.assign({ lat, lng }, types && { types })),
    })
    return response.data.results
  } catch (e) {
    return []
  }
}

export async function fetchGeoPlace({ id, language }) {
  if (!id) return {}
  try {
    const response = await axios(settings.geo.place, {
      params: new URLSearchParams(Object.assign({ id }, language && { language })),
    })
    return response.data
  } catch (e) {
    return {}
  }
}
