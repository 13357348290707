import { schema } from 'normalizr'
import { Record } from 'immutable'
import { CurrencySchema } from './currencies'

export const ReferralWalletSchema = new schema.Entity(
  'referralWallets',
  { currency: CurrencySchema },
  { idAttribute: (referralWallet) => 1 },
)

export const ReferralWalletRecord = Record({
  amount: undefined,
  currency: undefined,
})

export const emptyReferralWallet = new ReferralWalletRecord()
