import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { AmenitySchema, AlcoholSchema, FoodSchema } from 'redux/schemas'

export const amenitiesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'amenities' })
export const alcoholsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'alcohols' })
export const dietsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'diets' })
export const foodsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'foods' })
export const languagesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'languages' })

export const fetchAmenities = (query) =>
  amenitiesActions.fetch({
    url: '/amenities',
    query,
    schema: { amenities: [AmenitySchema] },
  })

export const fetchAlcohols = (query) =>
  alcoholsActions.fetch({
    url: '/alcohols',
    query,
    schema: { alcohols: [AlcoholSchema] },
  })

export const fetchFoods = (query) =>
  foodsActions.fetch({
    url: '/foods',
    query,
    schema: { foods: [FoodSchema] },
  })
