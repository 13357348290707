import { createSelector } from 'reselect'
import { getEntities, isFetching } from './_utils'
import { emptyTripAdvisorUser } from 'redux/schemas'
import { getUser } from './users'

export const getTripAdvisorUsers = getEntities('tripAdvisorUsers')

export const getTripAdvisorIdFromUser = createSelector(
  getUser,
  (user) => user.properties && user.properties.get('tripadvisor_id'),
)

export const getTripAdvisorUser = createSelector(getTripAdvisorUsers, getTripAdvisorIdFromUser, (taUsers, taId) =>
  taId ? taUsers.get(String(taId), emptyTripAdvisorUser) : null,
)

export const fetchingTripAdvisorUser = isFetching('/tar/location')

export const getTripAdvisorTopRankingText = createSelector(getTripAdvisorUser, (tripAdvisorUser) => {
  if (!tripAdvisorUser || !tripAdvisorUser.ranking_data) return null
  const ranking = parseInt(tripAdvisorUser.ranking_data.get('ranking'))
  const total = parseInt(tripAdvisorUser.ranking_data.get('ranking_out_of'))
  const isTop10Percent = total && ranking / total <= 0.1
  return isTop10Percent ? tripAdvisorUser.ranking_data.get('ranking_string') : null
})
