import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { InviteSchema } from 'redux/schemas'

export const invitesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'invites' })

export const fetchInvite = (token, query) => {
  return invitesActions.fetch({
    url: `/invites/${token}`,
    query,
    schema: { invite: InviteSchema },
  })
}

export const createInvite = (payload, query) =>
  invitesActions.create({
    url: '/invites',
    payload,
    query,
    schema: { invite: InviteSchema },
  })
