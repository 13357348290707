import { ActionsCreator, clearHandler, chainReducers } from '../lib'
import { screenApi } from 'apis'
import { loadStatsForIntercom, intercomLogout } from 'services/intercom'
import { COOKIE_KEYS, loadCookie, saveCookie, deleteCookie } from 'helpers/cookies'
import { gtmUserLoggedIn, gtmRegistered, gtmUserLoggedOut, getUserPayload } from 'gtm'
import { CurrentUserSchema } from '../schemas'
import {
  fetchReferralWallet,
  fetchCurrentUsers,
  fetchNotifications,
  toggleLoginModal,
  toggleRegisterModal,
  setRegisterModalFormType,
} from 'redux/actions'
import { getCurrentUser, getCurrentUserLanguage } from 'redux/selectors'
import staticCurrencies from 'static/currencies.json'

export const authActions = {
  LOGOUT: '@@account/LOGOUT',
}

export const accountActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'account' })

// AUTH
const setCookies = (token, language, rememberMe) => {
  screenApi.setHeader('Authorization', `JWT ${token}`)

  saveCookie({ name: COOKIE_KEYS.TOKEN, value: token, duration: 3600 * 24 * 60 })
  saveCookie({
    name: COOKIE_KEYS.NEWSLETTER_SIGNUP,
    value: true,
    duration: 365 * 24 * 3600,
  })
  saveCookie({
    name: COOKIE_KEYS.REMEMBER_ME_AUTHENTICATION,
    value: rememberMe,
    duration: 3600 * 24 * 90,
  })
}

export const handleRegister =
  (type, { payload: { data } }) =>
  (_dispatch, getState) => {
    const token = data.result.token
    if (!data.result.user || !token) throw new Error(`Missing data after registering with ${type}`)
    const state = getState()
    const language = getCurrentUserLanguage(state)
    const rememberMe = true
    return setCookies(token, language, rememberMe)
  }

function saveGAUserInLocalStorage(user) {
  if (typeof window !== 'undefined') {
    // not the most elegant way but we need to share the user across
    // serval gtm call.
    // The next refacto will be to wrap the gtm calls in custom hooks
    try {
      window.localStorage.setItem('gaUser', JSON.stringify(user))
    } catch (e) {}
  }
}

async function sendGAUser(user, type) {
  const gaUser = await getUserPayload(user)
  saveGAUserInLocalStorage(gaUser)
  gtmUserLoggedIn({ user: gaUser, type })
}

async function sendNewGAUser(user, type, isCreated) {
  const gaUser = await getUserPayload(user, isCreated)
  saveGAUserInLocalStorage(gaUser)
  gtmRegistered({ user: gaUser, type })
}

async function removeGAUser() {
  if (typeof window !== 'undefined') {
    try {
      window.localStorage.removeItem('gaUser')
    } catch (e) {}
  }

  gtmUserLoggedOut()
}

export const setAuthenticationData =
  ({
    type,
    result: {
      payload: { data },
    },
    cookies,
  }) =>
  (dispatch, getState) => {
    const token = data.result.token || (cookies && cookies.cookieToken)
    if (!data.result.user || !token) throw new Error(`Missing data after ${type} login`)

    const state = getState()
    const user = getCurrentUser(state)
    const currentUserLanguage = getCurrentUserLanguage(state)
    const isSocialAuth = ['facebook', 'google'].includes(type)
    const rememberMe = isSocialAuth || (cookies && cookies.cookieRememberMe)
    setCookies(token, currentUserLanguage, rememberMe)

    // TODO: setup sentry context here
    if (typeof window !== 'undefined' && window.apm && typeof window.apm.setUserContext === 'function') {
      window.apm.setUserContext({
        id: user.id,
        username: `${user.firstname} ${user.lastname}`,
      })
      window.apm.setCustomContext({
        locale: currentUserLanguage.locale,
        currency: staticCurrencies.data.find(({ id }) => id === user.currency).iso_3,
      })
    }

    dispatch(fetchNotifications(0))
    dispatch(fetchReferralWallet())
    sendGAUser(user, type)
    dispatch(toggleLoginModal({ show: false }))
    dispatch(toggleRegisterModal(false))
    screenApi.get('/users/stats').then(({ data: stats }) => {
      loadStatsForIntercom(user, stats)
    })
  }

function removeAuthenticationData() {
  intercomLogout()
  removeGAUser()
}

// REGISTER
export const registerWithEmail = (payload) => (dispatch) => {
  return dispatch(
    accountActions.create({
      url: '/register',
      payload,
      schema: { user: CurrentUserSchema },
    }),
  ).then((result) => {
    const {
      payload: { payload, data },
    } = result
    const user = { ...payload, id: data.result.user }
    dispatch(handleRegister('email', result))
    return sendNewGAUser(user, payload.type, data.result.created)
  })
}

// LOGIN
export const loginWithEmail = (payload) => (dispatch) => {
  return dispatch(
    accountActions.create({
      url: '/login',
      payload,
      schema: { user: CurrentUserSchema },
    }),
  ).then((result) =>
    dispatch(
      setAuthenticationData({
        type: 'email',
        result,
        cookies: { cookieRememberMe: payload.rememberMe },
      }),
    ),
  )
}

export const authByCookie = () => (dispatch) => {
  screenApi.removeHeader('Authorization')
  const cookieToken = loadCookie(COOKIE_KEYS.TOKEN)
  const cookieRememberMe = loadCookie(COOKIE_KEYS.REMEMBER_ME_AUTHENTICATION) === 'true'
  const cookieIsAdmin = loadCookie(COOKIE_KEYS.VIZEAT_ADMIN) === 'true'
  if (!cookieToken || (!cookieIsAdmin && !cookieRememberMe)) return removeAuthenticationData()

  return dispatch(
    fetchCurrentUsers({
      headers: Object.assign(screenApi.config.headers, { authorization: `JWT ${cookieToken}` }),
    }),
  )
    .then((result) => {
      dispatch(
        setAuthenticationData({
          type: 'cookie',
          result,
          cookies: { cookieToken, cookieRememberMe },
        }),
      )
    })
    .catch((err) => {
      removeAuthenticationData()
      throw err
    })
}

// SOCIAL
export const loginWithFacebook = (payload) => (dispatch) => {
  return dispatch(
    accountActions.create({
      url: '/login/facebook',
      payload: {
        ...payload,
        access_token: payload.authResponse.accessToken,
      },
      schema: { user: CurrentUserSchema },
    }),
  ).then((result) => {
    if (result.payload.error) return
    if (result.payload.data.result.created) {
      // is new, register
      dispatch(toggleRegisterModal(true, true))
      dispatch(handleRegister('facebook', result))
      return dispatch(setRegisterModalFormType('facebook'))
    } else {
      // is login
      dispatch(toggleLoginModal({ show: false }))
      return dispatch(setAuthenticationData({ type: 'facebook', result }))
    }
  })
}

export const loginWithGoogle = (payload) => (dispatch) => {
  return dispatch(
    accountActions.create({
      url: '/login/google',
      payload: {
        ...payload,
        access_token: payload.authResponse.access_token,
      },
      schema: { user: CurrentUserSchema },
    }),
  ).then((result) => {
    if (result.payload.error) return
    if (result.payload.data.result.created) {
      // is new, register
      dispatch(toggleRegisterModal(true, true))
      dispatch(handleRegister('google', result))
      return dispatch(setRegisterModalFormType('google'))
    } else {
      // is login
      dispatch(toggleLoginModal({ show: false }))
      return dispatch(setAuthenticationData({ type: 'google', result }))
    }
  })
}

// LOGOUT
export const logout = () => (dispatch, getState) => {
  removeAuthenticationData()
  return dispatch({ type: authActions.LOGOUT })
}

const handleLogout = (state, action) => {
  deleteCookie(COOKIE_KEYS.TOKEN)
  deleteCookie(COOKIE_KEYS.REMEMBER_ME_AUTHENTICATION)
  screenApi.removeHeader('Authorization')
  return chainReducers(clearHandler('currentUsers'), clearHandler('stripeAccounts'))(state)
}

export const accountCustomHandlers = {
  [authActions.LOGOUT]: handleLogout,
}
