import { schema } from 'normalizr'
import { Record } from 'immutable'

export const AlcoholSchema = new schema.Entity('alcohols')
export const AlcoholRecord = Record({
  id: undefined,
  title: '',
  description: '',
})

export const emptyAlcohol = new AlcoholRecord()
