import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { ReferralWalletSchema } from 'redux/schemas'

export const referralWalletsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'referralWallets' })

export const fetchReferralWallet = () => {
  return referralWalletsActions.fetch({
    url: '/users/wallet',
    schema: { referral_wallet: ReferralWalletSchema },
  })
}
