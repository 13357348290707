// WE CANNOT RELY ON IMPORT FROM ./index.js FOR THIS DUE TO CIRCULAR DEPENDENCIES
import { AccountSchema, AccountRecord, emptyAccount } from './accounts'
import { AlcoholSchema, AlcoholRecord, emptyAlcohol } from './alcohols'
import { AmenitySchema, AmenityRecord, emptyAmenity } from './amenities'
import { BookingSchema, BookingRecord, emptyBooking } from './bookings'
import { CurrencySchema, CurrencyRecord, emptyCurrency } from './currencies'
import { CurrentUserSchema, CurrentUserRecord, emptyCurrentUser } from './currentUsers'
import { DestinationSchema, DestinationRecord, emptyDestination } from './destinations'
import { DietSchema, DietRecord, emptyDiet } from './diets'
import { EventSchema, EventRecord, emptyEvent } from './events'
import { FileSchema, FileRecord, emptyFile } from './files'
import { FoodSchema, FoodRecord, emptyFood } from './foods'
import { InviteSchema, InviteRecord, emptyInvite } from './invites'
import { HostApplicationSchema, HostApplicationRecord, emptyHostApplication } from './hostApplications'
import { LanguageSchema, LanguageRecord, emptyLanguage } from './languages'
import { IPGeolocationSchema, IPGeolocationRecord, emptyIPGeolocation } from './IPGeolocation'
import { NotificationSchema, SubjectRecord, emptySubject, NotificationRecord, emptyNotification } from './notifications'
import { OrganizationSchema, OrganizationRecord, emptyOrganization } from './organizations'
import { OverrideSchema, OverrideRecord, emptyOverride } from './overrides'
import { PartnerRecord, PartnerSchema, emptyPartner } from './partners'
import { PaymentMethodSchema, PaymentMethodRecord, emptyPaymentMethod } from './paymentMethods'
import { PlaceSchema, PlaceRecord, emptyPlace } from './places'
import { PricingSchema, PricingRecord, emptyPricing } from './pricings'
import { ReferralWalletSchema, ReferralWalletRecord, emptyReferralWallet } from './referralWallets'
import { RequestSchema, RequestRecord, emptyRequest } from './requests'
import { ScheduleSchema, ScheduleRecord, emptySchedule } from './schedules'
import { TripAdvisorUserSchema, TripAdvisorUserRecord, emptyTripAdvisorUser } from './tripAdvisorUsers'
import { UserSchema, UserRecord, emptyUser } from './users'

// circular definitions
EventSchema.define({ user: UserSchema })
UserSchema.define({ events: [EventSchema] })
BookingSchema.define({ request: RequestSchema })
RequestSchema.define({ bookings: [BookingSchema] })

// Entity mapping
export const schemasByEntities = {
  accounts: AccountSchema,
  alcohols: AlcoholSchema,
  amenities: AmenitySchema,
  bookings: BookingSchema,
  currencies: CurrencySchema,
  currentUsers: CurrentUserSchema,
  destinations: DestinationSchema,
  diets: DietSchema,
  events: EventSchema,
  files: FileSchema,
  foods: FoodSchema,
  invites: InviteSchema,
  hostApplications: HostApplicationSchema,
  languages: LanguageSchema,
  IPGeolocation: IPGeolocationSchema,
  notifications: NotificationSchema,
  organizations: OrganizationSchema,
  overrides: OverrideSchema,
  partners: PartnerSchema,
  paymentMethods: PaymentMethodSchema,
  places: PlaceSchema,
  pricings: PricingSchema,
  referralWallets: ReferralWalletSchema,
  requests: RequestSchema,
  schedules: ScheduleSchema,
  tripAdvisorUsers: TripAdvisorUserSchema,
  users: UserSchema,
}

export const recordsByEntities = {
  accounts: AccountRecord,
  alcohols: AlcoholRecord,
  amenities: AmenityRecord,
  bookings: BookingRecord,
  currencies: CurrencyRecord,
  currentUsers: CurrentUserRecord,
  destinations: DestinationRecord,
  diets: DietRecord,
  events: EventRecord,
  files: FileRecord,
  foods: FoodRecord,
  invites: InviteRecord,
  hostApplications: HostApplicationRecord,
  languages: LanguageRecord,
  IPGeolocation: IPGeolocationRecord,
  notifications: NotificationRecord,
  organizations: OrganizationRecord,
  overrides: OverrideRecord,
  partners: PartnerRecord,
  paymentMethods: PaymentMethodRecord,
  places: PlaceRecord,
  pricings: PricingRecord,
  referralWallets: ReferralWalletRecord,
  requests: RequestRecord,
  schedules: ScheduleRecord,
  subject: SubjectRecord,
  tripAdvisorUsers: TripAdvisorUserRecord,
  users: UserRecord,
}

export const emptyByEntities = {
  accounts: emptyAccount,
  alcohols: emptyAlcohol,
  amenities: emptyAmenity,
  bookings: emptyBooking,
  currencies: emptyCurrency,
  currentUsers: emptyCurrentUser,
  destinations: emptyDestination,
  diets: emptyDiet,
  events: emptyEvent,
  files: emptyFile,
  foods: emptyFood,
  invites: emptyInvite,
  hostApplications: emptyHostApplication,
  languages: emptyLanguage,
  IPGeolocation: emptyIPGeolocation,
  notifications: emptyNotification,
  organizations: emptyOrganization,
  overrides: emptyOverride,
  partners: emptyPartner,
  paymentMethods: emptyPaymentMethod,
  places: emptyPlace,
  pricings: emptyPricing,
  referralWallets: emptyReferralWallet,
  requests: emptyRequest,
  schedules: emptySchedule,
  subject: emptySubject,
  tripAdvisorUsers: emptyTripAdvisorUser,
  users: emptyUser,
}
