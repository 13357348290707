import { cloudFlareWorkersApi } from 'apis'
import { ActionsCreator } from '../lib'
import { TripAdvisorUserSchema } from 'redux/schemas'

export const tripAdvisorUsersActions = new ActionsCreator({
  Api: cloudFlareWorkersApi,
  actionTypesPrefix: 'tripAdvisorUsers',
})

export const fetchTripAdvisorUser = ({ id, locale }) =>
  tripAdvisorUsersActions.fetch({
    url: '/tar/location',
    query: { id, language: locale },
    schema: TripAdvisorUserSchema,
  })
