import { createSelector } from 'reselect'
import { getEntitiesState } from './_utils'
import { Map } from 'immutable'
import { konstants } from '@vizeat/helpers'
import { getCountryFromIPAddress } from './IPGeolocation'
import staticCurrencies from 'static/currencies.json'
const { DEFAULT_CURRENCY } = konstants

const getAppSettings = createSelector(getEntitiesState, (entitiesState) => entitiesState.get('appSettings') || Map())

export const getAppCurrencyIso3 = createSelector(
  getAppSettings,
  (settings) => settings.get('currency') || DEFAULT_CURRENCY.iso_3,
)

export const getAppCurrency = createSelector(getAppCurrencyIso3, (iso3) =>
  staticCurrencies.data.find((c) => c.iso_3 === iso3),
)

export const getInstagramLink = createSelector(getCountryFromIPAddress, (country) => {
  switch (true) {
    case country === 'ES':
      return 'https://www.instagram.com/eatwith_es/'
    case country === 'GB':
      return 'https://www.instagram.com/eatwithlondon/'
    case country === 'IL':
      return 'https://www.instagram.com/eatwith_il/'
    default:
      return 'https://www.instagram.com/eatwith/'
  }
})

export const getFacebookLink = createSelector(getCountryFromIPAddress, (country) => {
  switch (true) {
    case country === 'ES':
      return 'https://www.facebook.com/EatwithES/'
    case country === 'GB':
      return 'https://www.facebook.com/eatwithlondon/'
    case country === 'IL':
      return 'https://www.facebook.com/Eatwithil/'
    default:
      return 'https://www.facebook.com/Eatwith/'
  }
})
