import { schema } from 'normalizr'
import { Record, Map } from 'immutable'
import { CurrencyRecord } from './currencies'

export const PricingSchema = new schema.Entity(
  'pricings',
  {},
  {
    idAttribute: (entity) => entity.pricing.event_id,
    processStrategy: (entity) => ({
      campaign: entity.campaign,
      metadata: entity.metadata,
      payee: entity.pricing.payee,
      discount: entity.pricing.discount,
      fees: entity.pricing.fees,
      payer: entity.pricing.payer,
    }),
  },
)

export const PricingRecord = Record({
  metadata: new Map({
    euro_pricing: {
      event_price: undefined,
      booking_price: undefined,
      sub_total: undefined,
      fees: undefined,
      discount: undefined,
      total: undefined,
    },
  }),
  campaign: new Map({
    active: undefined,
    begin_at: undefined,
    code: undefined,
    description: undefined,
    end_at: undefined,
    id: undefined,
    title: undefined,
  }),
  payee: new Map({
    currency: new CurrencyRecord(),
    currency_id: undefined,
    price: undefined,
  }),
  discount: new Map({
    currency: new CurrencyRecord(),
    currency_id: undefined,
    discount_to_payee_conversion_rate: undefined,
    amount_in_discount_currency: undefined,
    amount_in_guest_currency: undefined,
    amount_in_event_currency: undefined,
  }),
  fees: new Map({
    currency: new CurrencyRecord(),
    currency_id: undefined,
    rate: undefined,
    vizeat: undefined,
    conversion: undefined,
    total: undefined,
  }),
  payer: new Map({
    currency: new CurrencyRecord(),
    currency_id: undefined,
    payee_to_payer_conversion_rate: undefined,
    unit_price: undefined,
    seats: undefined,
    sub_total: undefined,
    fees: undefined,
    discount: undefined,
    total_paid: undefined,
    unit_price_with_fees: undefined,
  }),
})

export const emptyPricing = new PricingRecord()
