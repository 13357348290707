import { createSelector } from 'reselect'
import { getEntities, isFetching } from './_utils'
export const getIPGeolocation = getEntities('IPGeolocation')

export const getCountryFromIPAddress = createSelector(
  getIPGeolocation,
  (locationData) => locationData?.first()?.country || '',
)

export const fetchingIPGeolocation = isFetching('/ipcountry')
