import { schema } from 'normalizr'
import { Record } from 'immutable'

export const NotificationSchema = new schema.Entity('notifications')

export const SubjectRecord = Record({
  id: undefined,
  type: undefined,
})

export const NotificationRecord = Record({
  id: undefined,
  type: undefined,
  body: undefined,
  subject: new SubjectRecord(),
  read_at: undefined,
  created_at: undefined,
})

export const emptyNotification = new NotificationRecord()
export const emptySubject = new SubjectRecord()
