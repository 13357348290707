import { screenApi } from 'apis'
import { ActionsCreator, clearHandler } from '../lib'
import { NotificationSchema } from 'redux/schemas'

export const notificationActions = {
  CLEAR_NOTIFICATIONS: '@@notifications/CLEAR_NOTIFICATIONS',
}

export const notificationsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'notifications' })

export const fetchNotifications = (offset, query, config) =>
  notificationsActions.fetch({
    url: `/notifications?offset=${offset}`,
    query,
    schema: { notifications: [NotificationSchema] },
    ...config,
  })

export const setNotificationAsRead = (id) => {
  return notificationsActions.patch({
    url: `/notifications/${id}`,
    payload: {},
    schema: { notification: NotificationSchema },
  })
}

export const markAllNotificationsAsRead = () => {
  return notificationsActions.patch({
    url: '/notifications',
    payload: {},
    schema: { notifications: [NotificationSchema] },
  })
}

export const clearNotifications = () => (dispatch, getState) =>
  dispatch({ type: notificationActions.CLEAR_NOTIFICATIONS })

export const notificationsCustomHandlers = {
  [notificationActions.CLEAR_NOTIFICATIONS]: clearHandler('notifications'),
  [notificationsActions.actionTypes.FETCHED]: (state, action) => {
    const {
      payload: {
        data: { result },
      },
    } = action
    if (result.total === undefined || result.total === null) return state
    return state.mergeIn(['metadata', 'notifications'], {
      count: result.total,
      unread: result.unread_count,
      unread_messages: result.unread_replies_count,
      offset: result.offset,
      size: result.size,
      order: result.notifications,
    })
  },
}
