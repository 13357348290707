import { createSelector } from 'reselect'
import {
  getEntities,
  getMetadata,
  isFetching,
  isPatching,
  getEntityOffset,
  getEntitySize,
  getEntityTotal,
} from './_utils'

export const getNotifications = getEntities('notifications')
export const getNotificationsMetadata = getMetadata('notifications')
export const getNotificationsOffset = getEntityOffset('notifications')
export const getNotificationsSize = getEntitySize('notifications')
export const getNotificationsTotal = getEntityTotal('notifications')
export const getNotificationsUnread = createSelector(getNotificationsMetadata, (metadata) => metadata.get('unread', 0))
export const getUnreadMessages = createSelector(getNotificationsMetadata, (metadata) =>
  metadata.get('unread_messages', 0),
)

// loading
export const fetchingNotifications = isFetching('/notifications')
export const patchingNotifications = isPatching('/notifications')
