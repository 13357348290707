import { useCallback, useMemo } from 'react'
import { useCurrencyContext } from 'hooks/currencies/useCurrencyContext'
import staticCurrencies from 'static/currencies.json'

export function useCurrency() {
  const { appCurrencyIso3, setAppCurrencyIso3 } = useCurrencyContext()

  const getCurrencyIso3ById = useCallback((id) => {
    const currency = staticCurrencies.data.find((c) => c.id === id)
    return currency?.iso_3
  }, [])
  const getCurrencyById = (id) => staticCurrencies.data.find((c) => c.id === id)
  const getCurrencyByIso3 = useCallback((iso3) => staticCurrencies.data.find((c) => c.iso_3 === iso3), [])

  const appCurrency = useMemo(() => getCurrencyByIso3(appCurrencyIso3), [appCurrencyIso3, getCurrencyByIso3])

  const setAppCurrency = useCallback(setAppCurrencyIso3, [setAppCurrencyIso3])

  return {
    appCurrencyIso3,
    appCurrency,
    currencies: staticCurrencies.data,
    getCurrencyById,
    getCurrencyByIso3,
    getCurrencyIso3ById,
    setAppCurrency,
  }
}
