import { cloudFlareWorkersApi } from 'apis'
import { ActionsCreator } from '../lib'

import { IPGeolocationSchema } from 'redux/schemas'

export const IPGeolocationActions = new ActionsCreator({
  Api: cloudFlareWorkersApi,
  actionTypesPrefix: 'IPGeolocation',
})

export const fetchIPGeolocation = (query) =>
  IPGeolocationActions.fetch({
    url: '/ipcountry',
    query,
    schema: { IPGeolocation: IPGeolocationSchema },
  })
