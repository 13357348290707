import { schema } from 'normalizr'
import { Record } from 'immutable'

export const AmenitySchema = new schema.Entity('amenities')
export const AmenityRecord = Record({
  id: undefined,
  picto: undefined,
  title: '',
  original_title: '',
})

export const emptyAmenity = new AmenityRecord()
