import { schema } from 'normalizr'
import { Record } from 'immutable'

export const FoodSchema = new schema.Entity('foods')
export const FoodRecord = Record({
  id: undefined,
  title: '',
  localized_title: '',
  original_title: '',
  description: '',
  localized_description: '',
})

export const emptyFood = new FoodRecord()
